import { graphql } from "gatsby";
import * as React from "react";
import { TryNowPageInner } from "../components";

type Props = {
  data: GatsbyTypes.SelfServePageQuery;
};

export const query = graphql`
  query SelfServePage {
    prismicSelfServePage {
      data {
        hero_title
        hero_content {
          raw
        }

        step_1_name
        step_2_name
        success_message {
          raw
        }

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }
  }
`;

export default function SelfServePage({ data }: Props) {
  const page = data.prismicSelfServePage?.data;
  if (!page) throw new Error("page missing");

  return (
    <TryNowPageInner
      prismicData={page}
    />
  );
}
